// routes

import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '@material-ui/styles';
import { CircularProgress, Backdrop } from '@material-ui/core';
import { Provider } from 'use-http';
import { toCamel } from 'convert-keys';
import Router from './routes';

// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
// ----------------------------------------------------------------------

export default function App() {
  const noteEndpointUrl = process.env.REACT_APP_NOTE_API_ENDPOINT;
  const { isLoading, error, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const theme = useTheme();
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return (
      <Backdrop theme={theme} open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  const options = {
    interceptors: {
      request: async ({ options }) => {
        const domain = 'https://tradenote.babarboy.com';
        const t = await getAccessTokenSilently({
          audience: `${domain}`
          // scope: 'read:current_user'
        });

        options.headers.Authorization = `Bearer ${t}`;
        return options;
      }
    },

    response: async ({ response }) => {
      const res = response;
      if (res.data) res.data = toCamel(res.data);
      return res;
    }
  };

  return (
    <ThemeConfig>
      <Provider url={noteEndpointUrl} options={options}>
        <ScrollToTop />
        <Router isAuthenticated={isAuthenticated} />
      </Provider>
    </ThemeConfig>
  );
}
