// material
import { styled } from '@material-ui/core/styles';
import { Card, Stack, Button, Container, Typography } from '@material-ui/core';
// layouts
import { useAuth0 } from '@auth0/auth0-react';
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { loginWithRedirect } = useAuth0();
  return (
    <RootStyle title="Login | Minimal-UI">
      <AuthLayout />

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Sign in to Minimal
            </Typography>

            <Button
              sx={{ mt: 2, height: '3em' }}
              variant="contained"
              // onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
              onClick={() =>
                loginWithRedirect({
                  // appState: { targetUrl: `${window.location.origin}/dashboard/app` }
                })
              }
            >
              Login
            </Button>
          </Stack>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
