import { makeStyles } from '@material-ui/styles';
import { Modal, Fade, TextField, Button, Typography, Grid } from '@material-ui/core';
import * as yup from 'yup';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import useFetch from 'use-http';
import { Icon } from '@iconify/react';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 16,
    boxShadow: theme.shadows[10],
    padding: theme.spacing(2, 4, 3)
  }
}));

const validationSchema = yup.object({
  sellPrice: yup.string('Enter sell price').required('Sell price is required')
});

DeleteNoteModal.propTypes = {
  showDeleteModal: PropTypes.bool.isRequired,
  hideDeletePopup: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired,
  note: PropTypes.shape({
    id: PropTypes.number,
    pair: PropTypes.string,
    buyPrice: PropTypes.string,
    currency: PropTypes.string,
    amount: PropTypes.string,
    sellPrice: PropTypes.string,
    currentPrice: PropTypes.string,
    sellAmount: PropTypes.string,
    status: PropTypes.string,
    hide: PropTypes.bool
  })
};

export default function DeleteNoteModal({ note, showDeleteModal, hideDeletePopup, deleteNote }) {
  const classes = useStyles();
  const { patch } = useFetch();

  const formik = useFormik({
    initialValues: {
      sellPrice: parseFloat(note.currentPrice)
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const req = {
        sellPrice: values.sellPrice.toString(),
        status: 'CLOSED'
      };
      await patch(`/notes/${note.id}`, req);
      deleteNote(note.id, req);
      resetForm({});
      hideDeletePopup();
    }
  });

  const handleClose = () => {
    hideDeletePopup();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={showDeleteModal}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={showDeleteModal}>
        <div className={classes.paper}>
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              sx={{ pt: 1 }}
            >
              <Icon icon="simple-line-icons:close" width={18} height={18} onClick={handleClose} />
            </Grid>
            <Grid container sx={{ p: 1 }}>
              <Grid item sx={{ py: 2 }} xs={12}>
                <Typography variant="h5">Close the deal {note.pair} ?</Typography>
              </Grid>

              <Grid item sx={{ py: 2 }} xs={12}>
                <TextField
                  fullWidth
                  id="sellPrice"
                  name="sellPrice"
                  label="Sell Price"
                  type="number"
                  value={formik.values.sellPrice}
                  inputProps={{ inputMode: 'decimal', step: '0.00000001' }}
                  onChange={formik.handleChange}
                  error={formik.touched.sellPrice && Boolean(formik.errors.sellPrice)}
                  helperText={formik.touched.sellPrice && formik.errors.sellPrice}
                />
              </Grid>
              <Grid item sx={{ py: 2 }} xs={12}>
                <Button color="primary" size="large" variant="contained" fullWidth type="submit">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Fade>
    </Modal>
  );
}
