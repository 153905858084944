// material
import { Box, Grid, Container, Typography, Button } from '@material-ui/core';
import { useEffect, useState } from 'react';
import useFetch from 'use-http';
import CreateNoteModal from '../components/_dashboard/note/CreateNoteModal';
import DeleteNoteModal from '../components/_dashboard/note/DeleteNoteModal';
// components
import Page from '../components/Page';
import Note from '../components/_dashboard/app/Note';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { get } = useFetch();
  const [notes, setNotes] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState({});

  useEffect(() => {
    const getNotes = async () => {
      try {
        const n = await get('/notes');
        setNotes(n);
      } catch (e) {
        console.log(e);
      }
    };
    getNotes();
  }, [get]);

  const addNote = async (note) => {
    setNotes((notes) => [...notes, note]);
  };

  const handleDeleteSuccess = async (id, data) => {
    setNotes(
      notes.map((n) => {
        if (n.id === id) {
          return { ...n, ...data };
        }
        return n;
      })
    );
  };

  const hideDeletePopup = () => {
    setShowDeleteModal(false);
  };

  return (
    <Page title="Dashboard | Minimal-UI">
      <CreateNoteModal
        showCreateModal={showCreateModal}
        setShowCreateModal={setShowCreateModal}
        addNote={addNote}
      />

      {showDeleteModal ? (
        <DeleteNoteModal
          showDeleteModal={showDeleteModal}
          hideDeletePopup={hideDeletePopup}
          deleteNote={handleDeleteSuccess}
          note={selectedNote}
        />
      ) : (
        <></>
      )}

      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3} sx={{ pb: 2 }}>
          <Grid item>
            <Typography variant="h5">Active</Typography>
          </Grid>
          <Grid sx={{ pt: 2.5, px: 2 }}>
            <Button
              variant="contained"
              onClick={() => {
                setShowCreateModal(true);
              }}
            >
              + Add new pair
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ pb: 3 }}>
          {notes
            .filter((n) => n.status === 'LIVE')
            .map((note, i) => (
              <Grid key={i} item xs={12} sm={6} md={3}>
                <Note
                  note={note}
                  handleOnClickCloseNote={() => {
                    setShowDeleteModal(true);
                    setSelectedNote(note);
                  }}
                />
              </Grid>
            ))}
        </Grid>

        <Typography variant="h5" sx={{ pb: 2 }}>
          Closed
        </Typography>
        <Grid container spacing={3} sx={{ pb: 3 }}>
          {notes
            .filter((n) => n.status !== 'LIVE')
            .map((note, i) => (
              <Grid key={i} item xs={12} sm={6} md={3}>
                <Note
                  note={note}
                  handleOnClickCloseNote={() => {
                    setShowDeleteModal(true);
                    setSelectedNote(note);
                  }}
                />
              </Grid>
            ))}
        </Grid>
      </Container>
    </Page>
  );
}
