import { makeStyles } from '@material-ui/styles';
import { Modal, Fade, TextField, Button, Typography, Grid } from '@material-ui/core';
import * as yup from 'yup';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import useFetch from 'use-http';
import { Icon } from '@iconify/react';
import { toUpper } from 'lodash-es';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 16,
    boxShadow: theme.shadows[10],
    padding: theme.spacing(2, 4, 3)
  }
}));

const validationSchema = yup.object({
  pair: yup.string('Enter pair name').required('Pair name is required'),
  currency: yup.string('Enter currency').required('Currency is required'),
  buyPrice: yup.number('Enter buy price').required('Buy price is required'),
  amount: yup.number('Enter amount').required('Amount is required')
});

CreateNoteModal.PropType = {
  showCreateModal: PropTypes.bool.isRequired,
  setShowCreateModal: PropTypes.func.isRequired
};

export const DisplayFormikState = (props) => (
  <div style={{ margin: '1rem 0' }}>
    <pre
      style={{
        background: '#f6f8fa',
        fontSize: '.65rem',
        padding: '.5rem'
      }}
    >
      <strong>props</strong> = {JSON.stringify(props, null, 2)}
    </pre>
  </div>
);

// eslint-disable-next-line react/prop-types
export default function CreateNoteModal({ showCreateModal, setShowCreateModal, addNote }) {
  const classes = useStyles();
  const { post } = useFetch();

  const formik = useFormik({
    initialValues: {
      pair: 'BTCUSDT',
      buyPrice: 0.0,
      currency: 'USDT',
      amount: 0.0
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const res = await post('/notes', values);
      setShowCreateModal(false);
      addNote(res);
      resetForm({});
    }
  });

  const handleClose = () => {
    setShowCreateModal(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={showCreateModal}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={showCreateModal}>
        <div className={classes.paper}>
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              sx={{ pt: 1 }}
            >
              <Icon icon="simple-line-icons:close" width={18} height={18} onClick={handleClose} />
            </Grid>
            <Grid container sx={{ p: 1 }}>
              <Grid item sx={{ py: 2 }} xs={12}>
                <Typography variant="h5">Create a new pair</Typography>
              </Grid>
              <Grid item sx={{ py: 2 }} xs={12}>
                <TextField
                  fullWidth
                  id="pair"
                  name="pair"
                  label="Pair"
                  type="text"
                  value={formik.values.pair}
                  onChange={(e) => {
                    e.target.value = toUpper(e.target.value);
                    return formik.handleChange(e);
                  }}
                  error={formik.touched.pair && Boolean(formik.errors.pair)}
                  helperText={formik.touched.pair && formik.errors.pair}
                />
              </Grid>

              <Grid item sx={{ py: 2 }} xs={12}>
                <TextField
                  fullWidth
                  id="currency"
                  name="currency"
                  label="Currency"
                  type="text"
                  value={formik.values.currency}
                  onChange={(e) => {
                    e.target.value = toUpper(e.target.value);
                    return formik.handleChange(e);
                  }}
                  error={formik.touched.currency && Boolean(formik.errors.currency)}
                  helperText={formik.touched.currency && formik.errors.currency}
                />
              </Grid>

              <Grid item sx={{ py: 2 }} xs={12}>
                <TextField
                  fullWidth
                  id="buyPrice"
                  name="buyPrice"
                  label="Buy Price"
                  type="number"
                  value={formik.values.buyPrice}
                  inputProps={{ inputMode: 'decimal', step: '0.00000001' }}
                  onChange={formik.handleChange}
                  error={formik.touched.buyPrice && Boolean(formik.errors.buyPrice)}
                  helperText={formik.touched.buyPrice && formik.errors.buyPrice}
                />
              </Grid>

              <Grid item sx={{ py: 2 }} xs={12}>
                <TextField
                  fullWidth
                  id="amount"
                  name="amount"
                  label="Amount"
                  type="number"
                  value={formik.values.amount}
                  inputProps={{ inputMode: 'decimal', step: '0.00000001' }}
                  onChange={formik.handleChange}
                  error={formik.touched.amount && Boolean(formik.errors.amount)}
                  helperText={formik.touched.amount && formik.errors.amount}
                />
              </Grid>
              <Grid item sx={{ py: 2 }} xs={12}>
                <Button color="primary" size="large" variant="contained" fullWidth type="submit">
                  Submit
                </Button>
              </Grid>

              {/* <DisplayFormikState props={formik.values} /> */}
            </Grid>
          </form>
        </div>
      </Fade>
    </Modal>
  );
}
