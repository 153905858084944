import PropTypes from 'prop-types';

import { Icon } from '@iconify/react';

// material
import { alpha, styled } from '@material-ui/core/styles';
import { Card, Grid, TextField, Typography } from '@material-ui/core';
// utils
import { useState, useEffect } from 'react';
import { fCurrency } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme, pnl }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(2, 0),
  color: pnl > 0 ? theme.palette.primary.darker : theme.palette.error.darker,
  backgroundColor: pnl > 0 ? theme.palette.primary.lighter : theme.palette.error.lighter
}));

const IconWrapperStyle = styled('div')(({ theme, pnl }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: pnl > 0 ? theme.palette.primary.dark : theme.palette.error.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    pnl > 0 ? theme.palette.primary.dark : theme.palette.error.dark,
    0
  )} 0%, ${alpha(pnl > 0 ? theme.palette.primary.dark : theme.palette.error.dark, 0.24)} 100%)`
}));

// ----------------------------------------------------------------------

Note.propTypes = {
  note: PropTypes.shape({
    id: PropTypes.number,
    pair: PropTypes.string,
    buyPrice: PropTypes.string,
    currency: PropTypes.string,
    amount: PropTypes.string,
    sellPrice: PropTypes.string,
    currentPrice: PropTypes.string,
    sellAmount: PropTypes.string,
    status: PropTypes.string,
    hide: PropTypes.bool
  }),
  handleOnClickCloseNote: PropTypes.func.isRequired
};

// eslint-disable-next-line react/prop-types
export default function Note({ note, handleOnClickCloseNote }) {
  const [cost] = useState(note.amount * note.buyPrice);
  const [, setCurrentPrice] = useState();
  const [currentValue, setCurrentValue] = useState();
  useEffect(() => {
    setCurrentPrice(note.currentPrice);

    if (note.status === 'CLOSED') {
      setCurrentValue(note.amount * note.sellPrice - cost);
    } else {
      setCurrentValue(note.amount * note.currentPrice - cost);
    }
  }, [note.amount, cost, note.id, note.currentPrice, note.status, note.sellPrice]);

  const handleCloseButton = (noteId) => {
    handleOnClickCloseNote(noteId);
  };

  return (
    <RootStyle pnl={currentValue}>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
        sx={{ mb: 2, pr: 2 }}
      >
        <Icon
          icon="simple-line-icons:close"
          width={18}
          height={18}
          onClick={() => {
            handleCloseButton(note.id);
          }}
        />
      </Grid>
      <IconWrapperStyle pnl={currentValue}>
        <Icon icon="cryptocurrency:xrp" width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3">{note.pair}</Typography>
      <Typography variant="h4">{fCurrency(currentValue, note.currency)}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        buy price {note.buyPrice} {note.currency}
      </Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        amount {note.amount}
      </Typography>
      <Grid sx={{ p: 1 }}>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          {note.status === 'CLOSED' ? 'closed at' : 'now price'} {note.currency}
        </Typography>
        <TextField
          disabled={note.status === 'CLOSED'}
          type="number"
          size="small"
          inputProps={{ style: { textAlign: 'center', min: 0 } }}
          value={note.status === 'CLOSED' ? note.sellPrice : note.currentPrice}
          onChange={(e) => {
            note.currentPrice = e.target.valueAsNumber;
            setCurrentPrice(e.target.valueAsNumber);
          }}
          sx={{ mt: 1 }}
        />
      </Grid>
    </RootStyle>
  );
}
